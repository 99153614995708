.centered-imageT {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: relative;
  overflow-x: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.imgBg{
  height: 200px
}

.title-bold {
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
}

.horizontalTabSize {
  font-size: 4em;
}

/* Fin taille de title par taille écran */


/* ------------------------------ HISTORY ------------------------------ */

.container-history {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: center;
  align-items: center;
  margin: 50px;
}

.container-history-img {
  width: 525px;
  text-align: center;
}

.container-history-img img {
  width: 90%;
}

.container-history-text {
  width: 525px;
  text-align: left;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}


/* ---------------------------- NOS MÉTIERS ----------------------------- */

.container-metiers {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
  padding-top: 100px;
  background-image: url(../../assets/images/homepage/bandeau-mission.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

.title-metier {
  font-size: 45px;
  margin: 0 0 30px 0;
}

.container-metiers-title {
  width: 300px;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  font-size: 25px;
  margin: 0 auto;
  /* padding: 4%; */
}

.cercle-metier {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background: black;
  margin: auto;
}


.big-container-card-right {
  width: 100%;
  display: grid;
  grid-template-columns: 17.5% 25% 15% 25% 17.5%;
  margin: -15px;
}

.big-container-card-left {
  width: 100%;
  display: grid;
  grid-template-columns: 17.5% 25% 15% 25% 17.5%;
  margin: -15px;
}

.container-card-text-left p {
  /* text-align: justify; */
  width: 404px;
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  margin-top: 5px;
}


.container-card-text-right p {
  /* text-align: justify; */
  width: 404px;
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  margin-bottom: 5px;
}

.container-card-text-left-1 {
  font-family: 'Montserrat';
  font-size: 20px;
  margin: 0;
  margin-bottom: 15px;
  margin-right: 240px;
}

.container-card-text-left-2 {
  font-family: 'Montserrat';
  font-size: 20px;
  margin: 0;
  margin-bottom: 15px;
  margin-right: 190px;
}

.container-card-text-right h2 {
  font-family: 'Montserrat';
  font-size: 20px;
  margin: 0;
  margin-bottom: 15px;
}

.container-card-text-left {
  grid-column-start: 2;
  grid-column-end: 3;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.container-card-text-right {
  text-align: start;
  grid-column-start: 4;
  grid-column-end: 5;
}

.container-card-img {
  text-align: center;
  grid-column-start: 3;
  grid-column-end: 4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-card-img img {
  width: 150px;
  max-height: 100%;
}

.vertical-line {
  border-left: 2px solid #000;
  height: 75px;
  margin: 0 20px;
}

/* NOS MOYENS */

.container-moyens-title {
  width: 100%;
  text-align: center;
  margin: 0 auto;
  padding-top: 100px;
}

.container-moyens-title h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  font-size: 25px;
  text-align: center;
  margin: 0;
}

.container-moyens-title h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  text-align: center;
  color: #757575;
  font-size: 16px;
}

.container-moyen {
  width: 100%;
}

.cercle-moyen {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background: black;
  margin: auto;
}

.container-moyen-items {
  width: 96%;
  display: flex;
  justify-content: center;
  gap: 2%;
  padding: 2%;
  padding-bottom: 100px;
}

.moyen-item {
  border: 1px solid #bbbbbb;
  width: 22%;
  text-align: center;
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 30px;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

.moyen-item div {
  margin-bottom: 25px;
}

.moyen-item h3 {
  font-weight: 800;
  font-size: 1.75vw;
}

.moyen-item h4 {
  font-weight: 500;
  font-size: 15px;
  margin: 0;
}

.moyen-item p {
  font-weight: 400;
  font-size: 15px;
  margin: 0;
}

.moyen-item img {
  width: 70%;
}

.container-carrousel {
  width: auto;
  height: 450px;
  position: relative;
}

.btn-carrousel-left {
  position: absolute;
  left: 5%;
  top: 38%;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.btn-carrousel-right {
  position: absolute;
  right: 5%;
  top: 38%;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.pagination {
  position: absolute;
  left: 40%;
  bottom: -80px;
  display: flex;
  justify-content: center;
  /* margin-top: 20px; */
}

.indicator {
  width: 10px;
  height: 10px;
  background-color: #ccc;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.indicator.active {
  background-color: #000;
}

.history-text-title {
  padding: 5%;
  margin: 0;
  font-size: 45px;
}

.history-text-subtitle {
  padding: 5%;
  padding-top: 0;
  font-weight: bold;
  margin: 0;
  font-size: 17px;
}

.history-text {
  padding: 5%;
  margin: 0;
  padding-top: 0;
  font-size: 17px;
}

.bold {
  font-weight: bold;
}

@media (max-width: 1179px) {
  .vertical-line {
    display: none;
  }

  .big-container-card-left {
    width: 400px;
    height: auto;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    margin: 0;
  }

  .big-container-card-right {
    width: 400px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
  }

  .container-card-text-left {
    padding: 0 5% 0 5%;
    /* text-align: justify; */
    align-items: center;
  }

  .container-card-text-left h2 {
    text-align: center;
    font-size: 25px;
    margin: auto;
  }

  .container-card-text-right h2 {
    text-align: center;
    font-size: 25px;
    margin: auto;
  }

  .container-card-img {
    text-align: center;
  }

  .container-card-img img {
    width: 150px;
    max-height: 100%;
  }

  /* --------------------- */

  .container-moyen-items {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .moyen-item {
    width: 70%;
  }

  .moyen-item img {
    width: 46%;
  }

  .moyen-item h3 {
    font-size: 3.75vw;
    margin: 25px;
  }

  .moyen-item h4 {
    font-size: 1.95vw;
  }

  .moyen-item p {
    font-size: 1.75vw;
  }

}

@media (max-width: 850px) {

  .history-text-title{
    font-size: 25px;
  }

  .history-text-subtitle{
    font-size: 20px;
  }

  .history-text{
    font-size: 19px
  }

  .title-metier{
    font-size: 25px;
  }

  .container-moyens-title {
    width: 100%;
    padding-top: 20px;
  }

  .cercle-metier{
    width: 15px;
    height: 15px;
  }

  .cercle-moyen {
    display: none;
  }

  .container-history {
    width: 91%;
    padding: 0 2% 10% 2%;
  }

  .moyen-item h4 {
    font-size: 2.95vw;
  }

  .moyen-item p {
    font-size: 2.85vw;
  }

  .moyen-item img {
    width: 60%;
  }
}

@media (max-width: 460px) {

  .btn-carrousel-left {
    top: 36%;
  }

  .btn-carrousel-right {
    top: 36%;
  }

  .icon-lateral-text {
    text-align: center;
  }

  .big-container-card-left {
    width: 250px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
  }

  /* .container-card-text-left {
      text-align: justify;
    } */
  .container-card-text-left h2 {
    text-align: center;
    font-size: 18px;
  }

  .big-container-card-right {
    width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  /* .container-card-text-right {
      text-align: justify;
    } */
  .container-card-text-right h2 {
    text-align: center;
    font-size: 18px;
  }

  .container-card-img {
    text-align: center;
  }

  .container-moyen-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
  }

  .moyen-item {
    margin-bottom: 20px;
    width: 90%;
    padding: 1px;
  }

  .moyen-item h3 {
    font-size: 6.95vw;
  }

  .moyen-item h4 {
    font-size: 4.95vw;
  }

  .moyen-item p {
    font-size: 4.85vwvw;
  }

}

@media (min-width: 420px) and (max-width: 1179px) {
  .container-carrousel-moyen {
    display: flex;
    gap: 5%;
  }


}

@media screen and (min-width: 320px) and (max-width: 459px) {
  .container-card-text-right {
    display: flex;
    width: 300px;
    flex-direction: column;
    align-items: center;
    margin: auto;
  }

  .container-card-text-left {
    display: flex;
    width: 300px;
    flex-direction: column;
    align-items: center;
    margin: auto;
  }

  .big-container-card-left {
    width: 350px;
  }

  .big-container-card-right {
    width: 350px;
  }

  .container-card-text-left p {
    width: 257px;
    margin-top: 25px;
    margin-bottom: -15px;
  }

  .container-card-text-right p {
    width: 257px;
    margin-top: 25px;
    margin-bottom: -15px;
  }

  .pagination {
    bottom: -160px;
  }

  .container-metiers {
    height: 755px;
  }
}

@media screen and (min-width: 460px) and (max-width: 859px) {
  .container-metiers {
    height: 700px;
  }
}