.centered-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
  overflow-x: hidden;
}

.img-conditionnement{
  max-height: 150px;
  ;
}

.arrow-blue{
  filter: brightness(0) saturate(100%) invert(60%) sepia(74%) saturate(382%) hue-rotate(181deg) brightness(88%) contrast(86%);
}

/* 
  .centered-title {
    position: absolute; 
    top: 50%; 
    transform: translateY(-50%); 
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
  } */

.title-bold {
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
}

/* Taille de title par taille écran */
/* .telSize {
    font-size: 1.5em;
    text-align: center;
  /* } 
  .verticalTabSize {
    font-size: 2.1em;
  } */
.horizontalTabSize {
  font-size: 4em;
}

/* .desktopSize {
    font-size: 4em;
  } */


/* VOTRE FORMULE */

h1.NotreFormula-mesure {
  font-family: 'Montserrat', sans-serif;
  color: #6E9CD2;
  font-weight: 800;
  font-size: 3.7em;
  width: 99%;
  line-height: 46px;
  text-align: center;
}

.plus-sign-mesure {
  color: #6E9CD2;
  font-size: 10em;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  margin-top: -21px;
}

.equal-sign-mesure {
  color: #6E9CD2;
  font-size: 10em;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
}

.index {
  position: absolute;
  /* left: 37%; */
  top: -21%;
  font-size: 30px;
  font-family: montserrat;
  font-weight: bold;
}

.bleu {
  color: #6E9CD2;
}

.text-blue {
  color: #6E9CD2;
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: 0;
}

.formula-text p {
  font-size: 17px;
  margin: 0px;
}

.text-subtitle{
  font-weight: normal;
  margin: 0;
}

.sur-mesure{
  max-width: 550px;
  margin-left: -5%;
  margin-right: -10px;
}

@media screen and (max-width: 768px) {
  .index {
    top: -13%;
    font-size: 25px;
  }
}

@media screen and (min-width: 738px) and (max-width: 1181px) {
  .galenique {
    gap: 18%;
  }
}

@media screen and (min-width: 1180px) and (max-width: 1523px) {
  h1.NotreFormula-mesure {
    font-size: 45px;
  }

  h2.NotreCatalogue-mesure {
    font-size: 27px;
  }

  h1.packaging {
    font-size: 45px;
  }

  .equal-sign-mesure {
    width: 10%;
    text-align: right;
  }
}

@media screen and (max-width: 767px) {

  .container-formula {
    text-align: center;
    width: 100%;
  }

  h1.packaging {
    font-size: 45px;
    font-weight: bold;
    width: 80%;
    margin-top: 2px;
    text-align: center;
  }

  h1.NotreFormula-mesure {
    font-size: 45px;
  }

  .plus-sign-mesure {
    font-size: 110px;
    line-height: 84px;
  }

  .equal-sign-mesure {
    font-size: 130px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    width: 20%;
    line-height: 96px;
  }

  .formula-text p {
    font-size: 15px;
  }

}

@media (max-width: 850px) {

  .formula-text p {
    color: black;
    font-size: 19px;
  }

  .text-blue{
    font-size: 25px;
    margin: 0;
  }

  .text-subtitle{
    font-size: 19px
  }
}