.centered-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

.divTitle {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 50%;
  transform: translate(-5%, -50%);
  align-items: center;
}

.centered-title {
  margin-bottom: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

.title-bold {
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
}

/* Taille de title par taille écran */
.telSize {
  font-size: 39px;
  text-align: center;
}

.verticalTabSize {
  font-size: 54px;
}

.horizontalTabSize {
  font-size: 4em;
}

.desktopSize {
  font-size: 80px;
  line-height: 79px;
}

/* NOTRE FORMULE */

.sub-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1%;
  margin: 50px
}

.marque-blanche{
  max-width: 550px;
  margin-left: -5%;
  margin-right: -10px;
}

.subtile{
  font-size: 45px;
  line-height: 45px;
}

.sub-container-text {
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.plus-sign {
  color: #D9C5C4;
  font-size: 10em;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  margin-top: -21px;
}

.equal-sign {
  color: #D9C5C4;
  font-size: 10em;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  width: 10%;
  text-align: right;
}

.container-formula {
  width: 100%;
  text-align: center;
}

h1.NotreFormula {
  font-family: 'Montserrat', sans-serif;
  color: #D9C5C4;
  font-weight: 800;
  font-size: 3.7em;
  width: 99%;
  line-height: 46px;
  text-align: center;
}

.container-packaging {
  display: flex;
  justify-content: center;
  line-height: 45px;
}

h1.packaging {
  font-size: 45px;
  font-weight: bold;
  width: 89%;
  margin-top: 2px;
  text-align: center;
}

h2.NotreCatalogue {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 25px;
  width: 100%;
  line-height: 30px;
  margin-top: -27px;
  text-align: center;
  color: #757575
}

.container-formula-text {
  text-align: -webkit-center;
}

.formula-text {
  width: 640px;
  text-align: justify;
  font-family: 'Montserrat', sans-serif;
}

.text-intro {
  font-size: 17px;
  margin: 0;
  font-weight: normal;
}

.text-rose {
  color: #D1A9A7;
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: 0
}

.vertical-line-fb {
  border-left: 2px solid #000;
  display: inline-block;
  height: 75px;
  margin: 30px 20px;
}

/* NOS PROCESSUS DE FABRICATION */

.container-process {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-process-carousel {
  position: relative;
  height: 230px;
  width: 50%;
  max-width: 785px;
  margin-top: 3%;
}

.container-process-title h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 45px;
  font-weight: 100;
  margin: 30px
}

.container-pCard {
  
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.p-card {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-pCard h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 30px;
  font-weight: bold;
  line-height: 45px;
}

.btn-pcarousel-left {
  position: absolute;
  left: 5%;
  top: 25%;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.btn-pcarousel-right {
  position: absolute;
  right: 5%;
  top: 25%;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

/* NOS DIVERSES FORMES GALENIQUES */

.container-formes {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.container-process-formes-condition {
  width: 100%;
  text-align: center;
  margin-bottom: 10%;
}

.container-formes-title h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 45px;
  font-weight: 100;
  margin: 30px
}


.galenique {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6%;
  flex-wrap: wrap;
}

.galenique.scroll-horizontal {
  overflow-x: scroll;
}

.g-card {
  width: 280 px;
  text-align: justify;
  height: 450px;
}

.card-content h2 {
  font-size: 20px;
  font-weight: 500;
  font-family: 'Montserrat';
  text-align: center;
  margin: 5px
}

.card-content p {
  font-family: 'Montserrat';
  font-weight: 200;
  font-size: 16px;
}

/* NOTRE CHOIX DE CONDITIONNEMENT */

.container-condition-item {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10%;
}

.condition-item p {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 20px;
}

.container-condition {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-condition-title h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 50px;
  font-weight: 100;
  margin: 20px
}

.container-condition-title h3 {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin-top: -11px;
}

/* NOS DOMAINES D'APPLICATIONS */

.AreaOfExpertise {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Pour masquer la scrollbar dans Mozilla Firefox */
  scrollbar-width: none;
  scrollbar-color: transparent transparent;

}

.expertise-title h2 {
  font-size: 40px;
  font-weight: 100;
  margin: 10px;
}


.expertise-list {
  margin-top: 60px;
  width: 94%;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
}

.text-group {
  width: 258px;
}

.horizontal-scroll {
  overflow-x: auto;
  /* Enable horizontal scrolling */
  white-space: nowrap;
  /* Prevent line breaks for child elements */
}

/* Pour masquer la scrollbar dans Google Chrome et Microsoft Edge (basé sur Chromium) */
::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar-corner {
  display: none;
}


/* Pour masquer la scrollbar dans Safari */
::-webkit-scrollbar {
  width: 0;
}

.rose {
  color: #D9C5C4;
}

.card-content img {
  height: 176px;
  width: 265px;
}

/* FORMAT TABLETTE */

@media screen and (min-width: 768px) and (max-width: 1180px) {
  .sub-container {
    width: 100%;
    /* gap: 2%; */
    flex-direction: row;
    flex-wrap: nowrap;
  }


  .container-formula {
    width: 100%;
    margin-top: 2%;
  }

  h1.NotreFormula {
    font-size: 2em;
    width: 100%;
  }

  h2.NotreCatalogue {

    width: 100%;
    font-size: 24px;
  }

  .plus-sign {
    font-size: 9em;
  }

  h1.packaging {
    font-size: 45px;
    width: 100%;
  }

  .equal-sign {
    width: 10%;
  }

  .sub-container-img {
    width: 50%;
  }

  .sub-container-img img {
    width: 100%;
  }

  .galenique {
    gap: 14%;
  }

  .expertise-list {
    width: 80%;
  }


}

@media screen and (min-width: 1180px) and (max-width: 1523px) {
  .sub-container-text {
    width: 26%;
  }

  h1.NotreFormula {
    font-size: 3em;
  }

  h2.NotreCatalogue {
    font-size: 27px;
  }

  h1.packaging {
    font-size: 2.7em;
  }

  .equal-sign {
    width: 15%;
  }

  .sub-container-img {
    width: 45%;
  }

  .sub-container-img img {
    width: 100%;
  }

}

@media screen and (min-width: 1500px) {
  .expertise-list {
    justify-content: center;
  }
}

@media screen and (min-width: 1181px) and (max-width: 1478px) {
  .galenique {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 14%;
    flex-wrap: wrap;
  }

  .container-formes-title {
    width: 100%;
  }

  .container-formes {
    align-items: center;
  }

}

/* FORMAT MOBILE */
@media screen and (max-width: 750px) {
  .divTitle {
    transform: translate(0%, -50%);
    align-items: center;
  }
}

.g-card {
  width: 265px;
  height: 370px;
}

@media screen and (min-width: 320px) and (max-width: 324px) {
  .container-condition-item {
    gap: 8%;
  }
}

@media screen and (min-width: 411px) and (max-width: 588px) {
  .container-condition-item {
    width: 80%;
  }
}

@media screen and (min-width: 589px) and (max-width: 767px) {
  .container-condition-item {
    width: 60%;
  }
}

@media screen and (max-width: 500px) {
  .container-process-carousel {
    width: 94%;
  }

  .container-pCard h2 {
    font-size: 23px;
    line-height: 45px;
    margin-bottom: 0
  }

  .g-card {
    width: 265px;
    height: 390px;
  }
}

@media screen and (max-width: 530px) {
  .container-process-carousel {
    margin-top: 8%;
  }
}

@media screen and (min-width: 501px)and (max-width: 686px) {
  .container-process-carousel {
    width: 70%;
  }
}

@media screen and (min-width: 531px) and (max-width: 766px) {
  .container-process-carousel {
    margin-top: 6%;
    margin-bottom: 4%;
  }
}

@media screen and (min-width: 501px)and (max-width: 686px) {
  .container-process-carousel {
    width: 70%;
  }
}

@media screen and (min-width: 1300px) {

  .g-card {
    width: 265px;
    height: 370px;
  }
}

@media (max-width: 850px) {
  .text-rose{
    font-size: 25px;
  }

  .text-intro{
    font-size: 19px;
    font-weight: 400;
  }

  .formula-text p{
    color: black;
    font-size: 19px;
  }

  .text-intro{
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .container-process-title h2{
    font-size: 25px;;
  }

  .container-pCard h2 {
    font-size: 25px;
    margin-bottom: 0;
    line-height: 30px;
  }

  .container-formes-title h2 {
    font-size: 25px;
    font-weight: 100;
  }

  .card-content p {
    font-size: 19px;
    padding-bottom: 10px;
  }

  .container-process-carousel{
    height: 210px;
  }

  .container-condition-title h2{
    font-size: 25px;
  }

  .vertical-line-fb{
    margin-top: 20px;
  }

}

@media screen and (max-width: 1180px) {

  .text-intro{
    text-align: left;
    display: flex;
    justify-content: center;
  }

  .sub-container {
    margin: 0;
    padding: 20px;
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .sub-container-img{
    width: 100%;
    text-align: center;
  }

  .sub-container-text {
    width: 100%;
  }

  .container-formula {
    text-align: center;
    width: 100%;
  }

  h1.packaging {
    font-size: 30px;
    font-weight: bold;
    width: 80%;
    margin-top: 2px;
    text-align: center;
  }

  h1.NotreFormula {
    font-size: 33px;
  }

  h2.NotreCatalogue {

    width: 100%;
    font-size: 24px;
  }

  .plus-sign {
    font-size: 110px;
    line-height: 84px;
  }

  .equal-sign {
    color: #D9C5C4;
    font-size: 130px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    width: 20%;
    line-height: 96px;
  }

  .sub-container-img img {
    width: 100%;
  }

  .container-formula-text {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .formula-text {
    width: 80%;
    font-size: 12px;
    text-align: left;
  }

  .container-process-title h2 {
    font-size: 24px;
    font-weight: 100;
  }

  .container-formes {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .container-formes-title {
    width: 100%;
  }

  .container-formes-title h2 {
    font-size: 24px;
    font-weight: 100;
  }

  .container-condition-title h3 {
    font-size: 14px;
  }

  .container-condition-title {
    width: 100%;
  }

  .container-condition-title h2 {
    font-size: 27px;
  }

  .container-condition-item {
    flex-wrap: wrap;
  }

  .container-galenique-carousel {
    position: relative;
  }

  .btn-carousel-left {
    position: absolute;
    left: -15%;
    top: 25%;
    background-color: transparent;
    border: none;
  }

  .btn-carousel-right {
    position: absolute;
    right: -15%;
    top: 25%;
    background-color: transparent;
    border: none;
  }

  .AreaOfExpertise {
    width: 100%;
  }

  .expertise-title h2 {
    font-size: 32px;
  }

  .expertise-list {
    width: 80%;
  }
}

.buttonTitle {
  display: flex;
  justify-content: center;
}

.buttonTitle.desktopSizebut {
  color: rgb(255, 255, 255);
  background-color: black;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 185px;
  margin-left: 5px;
  margin-right: 5px;
  text-align: left;
  border-width: 1px 0 0 0;
  /* épaisseur de bordure : haut 1px, autres côtés 0 */
  border-style: solid;
  /* style de bordure : solide */
  border-color: black;
}

.buttonTitle.horizontalTabSizebut {
  color: rgb(255, 255, 255);
  background-color: black;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-width: 1px 0 0 0;
  /* épaisseur de bordure : haut 1px, autres côtés 0 */
  border-style: solid;
  /* style de bordure : solide */
  border-color: black;
  width: 180px;
  font-size: 14px;
  text-align: left;
}

.buttonTitle.verticalTabSizebut {
  color: rgb(255, 255, 255);
  background-color: black;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-width: 1px 0 0 0;
  /* épaisseur de bordure : haut 1px, autres côtés 0 */
  border-style: solid;
  /* style de bordure : solide */
  border-color: black;
  width: 170px;
  font-size: 12px;
  text-align: left;
}

.buttonTitle.telSizebut {
  color: rgb(255, 255, 255);
  background-color: black;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-width: 1px 0 0 0;
  /* épaisseur de bordure : haut 1px, autres côtés 0 */
  border-style: solid;
  /* style de bordure : solide */
  border-color: black;
  width: 155px;
  font-size: 11px;
  text-align: left;
}

.buttonTitle {
  transition: 0.5s;
  cursor: pointer;
}

.buttonTitle:hover {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
}

.divmiddlebouton {
  display: flex;
  justify-content: center;
  margin: 30px;
}

.buttonTitle {
  display: flex;
  justify-content: center;
}

.buttonTitle2.desktopSizebut {
  background-color: #000000;
  color: rgb(255, 255, 255);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 250px;
  margin-left: 5px;
  margin-right: 5px;
  text-align: left;
  border-width: 1px 0 0 0;
  /* épaisseur de bordure : haut 1px, autres côtés 0 */
  border-style: solid;
  /* style de bordure : solide */
  border-color: black;
}

.buttonTitle2.horizontalTabSizebut {
  color: rgb(255, 255, 255);
  background-color: #000000;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-width: 1px 0 0 0;
  /* épaisseur de bordure : haut 1px, autres côtés 0 */
  border-style: solid;
  /* style de bordure : solide */
  border-color: black;
  width: 180px;
  font-size: 14px;
  text-align: left;
}


.buttonTitle2.verticalTabSizebut {
  color: rgb(255, 255, 255);
  background-color: #000000;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-width: 1px 0 0 0;
  /* épaisseur de bordure : haut 1px, autres côtés 0 */
  border-style: solid;
  /* style de bordure : solide */
  border-color: black;
  width: 170px;
  font-size: 12px;
  text-align: left;
}

.buttonTitle2.telSizebut {
  color: rgb(255, 255, 255);
  background-color: #000000;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-width: 1px 0 0 0;
  /* épaisseur de bordure : haut 1px, autres côtés 0 */
  border-style: solid;
  /* style de bordure : solide */
  border-color: black;
  width: 155px;
  font-size: 11px;
  text-align: left;
}

.buttonTitle2 {
  transition: 0.5s;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.buttonTitle2:hover {
  background-color: #ffffff;
  color: rgb(0, 0, 0);

}