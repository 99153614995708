.image-formulaire{
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
}
.imageFr{
  width: 80%;
}

.form{
  display: flex;
  flex-direction: row;
  width: 100%;
}

.formMobile{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.div-input{
  display: flex;
  flex-direction: column;
}
.input-label {
  margin-bottom: 5px;
  font-size: 16px;
  color: #6D6869
}

.input-field {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 2px;
  width: 90%;
  margin-bottom: 10px;
}

.required {
  color: #E80000;
}

.div_moit-form{
  margin-left: 5%;
  margin-right: 5%;
  width: 100%;
}

button[type="submit"] {
  padding: 10px 20px;
  background-color: #ECECEC;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  font-size: medium;
}

.div-bouton{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;

}

.div-p{
  display: flex;
  justify-content: center;
}

.contact-title{
  text-align: center;
}