.centered-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: relative; 
    overflow-x: hidden;
  }

.container-more-info{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.line-container {
  height: 5px;
  overflow: hidden;
}

.link-more-info {
  text-decoration: none;
  color: black;
  margin-bottom: 5%;
}

.line-svg {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  transition: stroke-dashoffset 0.9s ease;
}

.container-video {
  width: 100%;
  position: relative;
}

.container-on-video {
  width: 70%;
  max-width: 1440px;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10%;
  position: absolute;
  bottom: 0;
  background-color: white;
  left: 50%;
  transform: translateX(-50%);
}

.item-icon-top {
  text-align: center;
  padding: 1%;
}

.item-icon-bottom {
  text-align: center;
  padding: 1%;
}

.item-icon-bottom h4{
  margin: 0;
}

.item-icon-top img {
  width: 64px;
  height: 65px;
}

.item-icon-bottom img {
  width: 90px;
}
.item-icon-bottom h4 {
  font-family: 'Montserrat';
  font-size: 12px;
  font-weight: 500;
}

.item-icon h4 {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-size: 16px;
}

.big-container-objectif {
  width: 100%;
  display: flex;
  justify-content: center;
}

.container-objectif {
  width: 80%;
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
}

.objectif-title {
  text-align: center;
  width: 100%;
  margin: 3% 0 2% 0;
}
.objectif-title h1{
  font-family: 'Montserrat';
  font-weight: 800;
  font-size: 40px;
  margin: 0;

}

.objectif-title h2 {
  font-weight: 800;
  font-size: 45px;
  margin: 0;
  padding-bottom: 5px;
}

.objectif-title h3 {
  font-weight: 400;
  font-size: 25px;
  margin: 0;
  color: #757575;
}

.container-objectif-paragraphes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5%;
  width: 100%;
  font-weight: 500;
  margin-top: 2%;
  margin-bottom: 4%;
}

.objectif-paragraphe1,
 .objectif-paragraphe2 {
  font-family: 'Montserrat';
  font-size: 17px;
  width: 40%;
  max-width: 412px;
  text-align: justify;
}
span.text-home {
  font-weight: 600;
}
span.paragraphe1 {
  font-weight: 700;
  font-size: 17px;
}

.container-mission{
  padding-left: 30%;
  padding-right: 30%;
  text-align: center;
}

.container-intervention {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(../../assets/images/homepage/bandeau-mission.jpg);
  width: 1870px;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 50px;
  padding-bottom: 50px
}

.section-mission p{
  text-align: center;
}

.intervention-title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  font-size: 40px;
  margin: 0 auto;
  width: 100%;
  text-align: center;
}

.container-item-intervention {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.container-nos-formules {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5%;
  padding-top: max(161px);
}

.container-intervention p {
  text-align: center;
}

.container-intervention{
  width: 100%;
}

.container-formule-item {
  background-color: #fff;
  border: 1px solid #bbbbbb;
  border-radius: 10px;
  width: 500px;
  height: 480px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  /* margin-top: 13%; */
  margin-bottom: 3%;

  position: relative;
  z-index: 1;
}

.container-formule-item img {
  width: 200px;
  position: absolute;
  z-index: 999;
  top: -21%;
}

.container-formule-item h2 {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 35px;
  margin-top: 0;
}

.container-formule-item h2 span {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.bleu{
  color: #6E9CD2;
}

.rose{
  color: #D9C5C4;
}

.descriptionBoxFormules {
  margin-bottom: 40px;
  width: 80%;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 18px;
}

.moreInfo-text{
  font-size: 14px;
}

.text{
  white-space: nowrap;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 400;
}

@media screen and (min-width: 320px) and (max-width: 400px) {
  .container-item-intervention {
    position: relative;
    width: 320px;
  }
  .container-intervention p {
    text-align: center;
    width: 100%;
  }
  }

@media screen and (min-width: 350px) and (max-width: 800px){
  .container-mission{
    padding-left: 5%;
    padding-right: 5%;
    text-align: center;
  }
}
  
@media screen and (min-width: 401px) and (max-width: 1179px) {
.container-item-intervention {
  position: relative;
  width: 400px;
}
.container-intervention p {
  text-align: center;
  width: 100%;
}
.item-icon-bottom img {
  width: 170px;
}
}

@media (max-width: 1179px) {

  .objectif-title h2 {
    margin-top: 25px;
    font-size: 25px;
    padding: 0;
  }

  .objectif-title h3 {
    margin-top: 10px;
    font-size: 20px;
  }

  .intervention-title {
    font-size: 25px;
  }

  .container-on-video {
    display: none;
  }

  .container-objectif-paragraphes {
    display: flex;
    flex-direction: column;
  }

  .objectif-paragraphe1,
  .objectif-paragraphe2 {
    width: 100%;
    max-width: none;
    text-align: justify;
  }
  .objectif-paragraphe3 {
    display: none;

  }

  .objectif-paragraphe1 {
    margin-bottom: 2%;
  }

  .container-nos-formules {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 4%;
    padding-bottom: 4%;
  }

  .container-formule-item {
    margin-top: 13%;
    width: 90%;
  }

  .container-formule-item img {
    width: 230px;
    top: -20%;
  }

  .btn-metier-left {
    position: absolute;
    left: 5%;
    top: 40%;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  
  .btn-metier-right {
    position: absolute;
    right: 5%;
    top: 40%;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  
  .pagination {
    position: absolute;
    left: 40%;
    bottom: 0;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .indicator {
    width: 10px;
    height: 10px;
    background-color: #ccc;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .indicator.active {
    background-color: #000;
  }
  
}

@media (max-width: 950px) {
  .objectif-title {
    text-align: center;
    width: 100%;
    margin: 3% 0 3% 0;
  }
}

@media (max-width: 650px) {
  .container-formule-item {
    margin-top: 25%;
  }

  .objectif-title {
    text-align: start;
    width: 100%;
    margin: 0;
  }

  .container-formule-item h2 {
    font-size: 30px;
  }
  .container-objectif-paragraphes {
    margin-top: 5%;
  }
}
