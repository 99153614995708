/* header.css */

.blackspace {
  background-color: #000;
  height: 25px;
  transition: width 0.5s ease;
  display: flex;
  justify-content: center;
}

.big-container-language {
  width: 1920px;
  display: flex;
  justify-content: end;
  align-items: center;
}

.container-language {
  z-index: 9999;
  width: 120px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.picker-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
}

.divLanguage {
  background-color: #fff;
  border-radius: 0 0 10px 10px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.divLanguage p {
  font-size: 12px;
  margin: 0;
  padding: 5px;
}

.container-language h4 {
  color: #fff;
  margin: 0;
  font-size: 12px;
}

.pickList {
  width: 12px;
  height: 12px;
}

.language {
  cursor: pointer;
}

.header {
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  position: fixed;
  width: 100%;
  z-index: 1000;
  transition: background-color 0.5s ease;
}

.header.scrolling {
  background-color: #fff;
  top: 0;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.05);
}

.logoDiv img {
  width: 100%;
  max-width: 250px;
}

.navigation ul {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
}

.navigation li {
  margin-right: 50px;
}

.navigation a,
.multilangue {
  text-decoration: none;
  color: #000;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  position: relative;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
}

/* Style du lien actif */
.navigation a.active {
  border-bottom: 2px solid #757575;
  padding-bottom: 4px;
}

/* Style des liens non actifs */
.navigation a:not(.active) {
  position: relative;
  text-decoration: none;
  color: #000;
}

.navigation a:not(.active)::before,
.navigation a:not(.active)::after {
  content: "";
  position: absolute;
  width: 0;
  border-bottom: 2px solid #757575;
  bottom: -4px;
  transition: width 0.3s ease-in-out;
}

.navigation a:not(.active)::before {
  left: 0;
}

.navigation a:not(.active)::after {
  right: 100%;
}

/* Animation au survol */
.navigation a:not(.active):hover::before {
  width: 100%;
}

.navigation a:not(.active):hover::after {
  width: 0;
}

/* ---------------------------------------------------------------------- */

/* Styles pour le menu burger */
.menu-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
  position: relative;
}

/* Styles pour le logo mobile (au-dessus des liens) */
.logo-mobile {
  display: none;
}

/* Styles pour le logo mobile (au-dessus des liens) lorsque le menu est ouvert */
.header.menu-open .logo-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 10px;
  top: 19%;
  left: 0;
  width: 100%;
}

/* Taille du logo */
.logo-mobile img {
  width: 150px;
  margin-left: 10px;
  height: auto;
  margin-bottom: 10px;
}


/* Styles pour le bouton de fermeture */
.close-button {
  background: none;
  border: none;
  font-size: 24px;
  color: #000;
  cursor: pointer;
  padding: 10px;
  position: absolute;
  top: 20px;
  right: 20px;
}

.bar {
  width: 30px;
  height: 3px;
  background-color: #000;
  margin: 4.2px 0;
  transition: 0.4s;
  transform-origin: center;
}

.header.menu-open .menu-toggle .bar:nth-child(1) {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.header.menu-open .menu-toggle .bar:nth-child(2) {
  opacity: 0;
}

.header.menu-open .menu-toggle .bar:nth-child(3) {
  transform: rotate(45deg) translate(-5px, -6px);
}

/* Media query pour afficher le menu burger sur les téléphones */
@media (max-width: 1110px) {

  .container-language {
    display: none;
  }

  /* Styles pour le header */
  .header {
    background: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    width: 100%;
  }

  .logoDiv img {
    height: 85px;
    padding-left: 20px;
  }

  .menu-toggle {
    display: flex;
    padding-right: 5%;
  }

  .header.menu-open .navigation {
    transform: translateX(0);
  }

  /* Styles pour le menu mobile */
  .navigation {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    transform: translateX(100%);
    transition: transform 0.4s ease-in-out;
    max-width: 100%;
    overflow-x: hidden;
  }

  .navigation ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .navigation li {
    margin: 15px 0;
  }

  .navigation a {
    text-decoration: none;
    color: #000;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
  }

  /* Styles pour le bouton de fermeture */
  .close-button {
    background: none;
    border: none;
    font-size: 24px;
    color: #000;
    cursor: pointer;
    padding: 10px;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .close-button:focus {
    outline: none;
  }

  .close-button::before {
    content: '×';
    font-weight: bold;
    margin-right: 5px;
  }
}

@media (max-width: 450px) {
  .header {
    height: auto;
    padding: 0;
  }

  .logoDiv img {
    width: 50%;
    height: auto;
    padding-top: 5%;
    padding-bottom: 5%;
  }
}