.big-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.centered-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: relative; 
    overflow-x: hidden;
  }
/* 
  .centered-title {
    position: absolute; 
    top: 50%; 
    transform: translateY(-50%); 
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
  } */

  .title-bold {
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
  }

  /* Taille de title par taille écran */
  /* .telSize {
    font-size: 1.5em;
    text-align: center;
  /* } 
  .verticalTabSize {
    font-size: 2.1em;
  } */
  .horizontalTabSize {
    font-size: 4em;
  }
  /* .desktopSize {
    font-size: 4em;
  } */

  .container-qualite-text {
    text-align: center;
    padding: 20px 0 10px 0;
    padding-left: 10%;
    padding-right: 10%;
  }

  .container-qualite-text h4 {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 20px;
}
  .container-qualite-text h4 span {
    font-weight: 600;

  }

  .container-formeGaleniqueIMG {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  .container-formeGaleniqueIMG img {
    width: 50%;
    min-width: 300px;
  }

  .big-container-card-qualite {
    width: 96%;
    display: flex;
    justify-content: center;
    gap: 2%;
    padding: 2%;
  }

  .container-card-qualite {
    padding: 20px;
    background-color: #fff;
    min-width: 320px;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 3%;
    border-radius: 7px;
    border: 1px solid #bbbbbb;
    width: 22%;
  }

  .container-card-qualite img {
    width: 50px;
  }

  .liste-description {
    text-align: left;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 15px;
    padding-right: 10px;
  }

  .container-qualite-title h2 {
    font-weight: 800;
    font-size: 1.75vw;
    margin-bottom: 0;
  }

  .container-qualite-title {
    font-family: 'Montserrat';
    font-weight: 800;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1%;
  }

  .container-service-qualite {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 3% 0 3% 0;
    flex-wrap: wrap;
    gap: 50px;
  }

  .container-service-qualite img{
    max-width: 100px;
  }
  

  .qualiteSoloText {
    font-weight: 300;
  }

  .text-rappel{
    text-align: center;
    padding: 30px;
  }

  .disclaimer{
    background-color: #EBEBE9;
    width: 100%;
  }

  @media screen and (min-width: 400px) and (max-width: 650px){
    .container-formeGaleniqueIMG img {
      min-width: 400px;
  }
  }

  @media (max-width: 700px) {
    .container-service-qualite img {
      margin: 15px 50px 15px 50px;
    }
  }

  @media (max-width: 506px) {
    .container-service-qualite img {
      width: 95px;
    }
  }

  @media (max-width: 1200px) {
    .big-container-card-qualite {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .container-card-qualite {
      width: 80%;
      max-width: 500px;
    }
    
  }
  
  @media (max-width: 1600px) {
    .container-qualite-title h2 {
      font-size: 25px;
    }
  }
  
  @media (max-width: 1300px) {
    .container-qualite-title h2 {
      font-size: 25px;
    }
  }

  @media (max-width: 950px) {
    .container-service-qualite img {
      margin: 15px 100px 15px 100px;
    }
    .container-qualite-text {
      text-align: center;
      padding-top: 20px;
    }
    .container-card-qualite{
      padding: 5px;
      margin-bottom: 30px;
    }

    .liste-description{
      margin-top: -3px;
    }

    .text-rappel{
      padding: 20px;
    }

    .container-service-qualite{
      gap: 0%;
      margin-top: 15px;
    }
  }